/*
  NO TIENEN LOGOS
  Argentalia, Carnevale, Cartones Coarrugados, Decomar,
  Del Paisa, Dun Dun, Facil Fix, Gauss, Gramajo, Handyman, Hermes,
  Maer, Malano, Perbond, Roggers, Rucar, Sagola, Tecno King, Teroson
*/

export default {
  '3 M': '3M.png',
  Aike: 'Aike.png',
  Akapol: 'Akapol.png',
  Anclaflex: 'Anclaflex.png',
  Argenspray: 'Argenspray.jpg',
  Aurax: 'Aurax.jpg',
  Bambin: 'Bambin.png',
  Basf: 'Basf.jpg',
  Baslac: 'Baslac.png',
  Brikol: 'Brikol.jpg',
  Casablanca: 'Casablanca.jpeg',
  Cetol: 'Cetol.jpg',
  'Colorin Auto': 'Colorin.png',
  Dixilina: 'Dixilina.jpg',
  'Doble A': 'DobleA.png',
  'El Coati': 'ElCoati.png',
  'El Galgo': 'ElGalgo.png',
  Emapi: 'Emapi.gif',
  'Escaleras Scala': 'EscalerasScala.png',
  'Espatulas Pinas': 'EspatulasPinas.png',
  'Ful Stik': 'Fulstik.png',
  Gauss: 'Gauss.png',
  Glasurit: 'Glasurit.png',
  Gillmore: 'Gillmore.png',
  'Indulac 3.0': 'Indulac.png',
  Kartonsec: 'Kartonsec.png',
  Kum: 'Kum.jpg',
  Kuwait: 'Kuwait.png',
  'La Hacendosa': 'LaHacendosa.jpg',
  'La Oveja': 'La_oveja.jpeg',
  Lajamax: 'Lajamax.jpg',
  Limco: 'Limco.png',
  Lumar: 'Lumar.png',
  Maderplast: 'Maderplast.png',
  Megaflex: 'Megaflex.png',
  Menzerna: 'Menzerna.png',
  Mota: 'Mota.jpg',
  Netcolor: 'Netcolor.jpg',
  Pentrilo: 'Pentrilo.png',
  Petrilac: 'Petrilac.png',
  Plavicon: 'Plavicon.jpg',
  'Plus Papier': 'PlusPapier.png',
  Polacrin: 'Polacrin.jpg',
  Polilak: 'Polilak.jpg',
  Poxipol: 'Poxipol.jpg',
  Presta: 'Presta.jpeg',
  Quimex: 'Quimex.png',
  Quimvar: 'Quimvar.png',
  Rapifix: 'Rapifix.png',
  Revear: 'Revear.jpg',
  Revesta: 'Revesta.png',
  Roberlo: 'Roberlo.png',
  Rodim: 'Rodim.jpeg',
  Roguant: 'Roguant.jpg',
  Rosarpint: 'Rosarpin.png',
  Rucar: 'Rucar.jpg',
  'Rust Oleum': 'RustOleum.png',
  Sagola: 'Sagola.png',
  'Santa Juana': 'SantaJuana.jpg',
  Sika: 'Sika.png',
  Siloc: 'Siloc.jpg',
  Sinteplast: 'Sinteplast.png',
  Skil: 'Skil.jpg',
  Solanatile: 'Lajamax.jpg',
  Sonax: 'Sonax.jpg',
  Suprabond: 'Suprabond.jpg',
  Tacsa: 'Tacsa.png',
  Tersuave: 'Tersuave.jpg',
  Tornado: 'Tornado.png',
  Trimas: 'Trimas.png',
  Unipega: 'Unipega.jpg',
  Venezia: 'Venezia.jpeg',
  Venier: 'Venier.png',
  Victoria: 'Victoria.png',
  Vitecso: 'Vitecso.png',
  Workolor: 'Wor_kolor.png',
  Zeocar: 'Zeocar.jpg',
  Zinsser: 'Zinsser.png',
};
